import anime from 'animejs/lib/anime.es';

export default function fadeTransition() {
    const target = document.querySelector('.js-fade-transition');
    const targetContainer = document.querySelector('.js-fade-transition-container');
    const duration = 800;
    const easing = 'easeInOutQuart';

    return {
      name: 'fade-transition',

      // Le rideau apparaît
      leave: () => new Promise((resolve) => {
        target.style.display = 'block';

        anime.timeline({
          targets: targetContainer,
          duration,
          easing,
        }).add({
          opacity: 1,
        }).finished.then(() => {
          resolve();
        });
      }),

      // Le rideau disparaît
      enter: () => new Promise((resolve) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        anime.timeline({
          targets: targetContainer,
          duration,
          easing,
        }).add({
          opacity: 0,
        }).finished.then(() => {
          target.style.display = '';
          resolve();
        });
      }),

      // Afficher le rideau
      beforeOnce: () => new Promise((resolve) => {
        target.style.display = 'block';
        targetContainer.style.opacity = 1;
        resolve();
      }),

      // Le rideau disparait en opacité
      once: () => new Promise((resolve) => {
        anime.timeline({
          targets: targetContainer,
          duration: 1000,
          easing,
        }).add({
          opacity: 0,
        }).finished.then(() => {
          targetContainer.style.opacity = '';
          target.style.display = '';
          resolve();
        });
      }),
    };
  }
