import $ from 'jquery';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'bootstrap-datepicker';
import 'jquery-validation';
import 'jquery-mask-plugin';
import 'jquery-validation/dist/additional-methods';
import 'jquery.easing';
import './barba';

window.jQuery = $;
window.$ = $;
