//------------------------------\\
//---- MouseoverEvent class ----\\
//------------------------------\\

export class MouseoverEvent {

  constructor(instance) {
    if (instance !== undefined)
      this.instance = instance

    this.init()
  }


  init() {
    let i

    this.openButtons = document.querySelectorAll(this.instance.overlay.structure.buttons.toggle + ',' + this.instance.overlay.structure.buttons.open)
    let openButtonsLength = this.openButtons.length
    for (i = 0; i < openButtonsLength; i++) {
      this.boundOpen = e => this.instance.open(e)
      this.openButtons[i].addEventListener('mouseover', this.boundOpen, false)
    }

    this.closeButtons = document.querySelectorAll(this.instance.overlay.structure.buttons.close)
    let closeButtonsLength = this.closeButtons.length
    for (i = 0; i < closeButtonsLength; i++) {
      this.boundClose = e => this.instance.close(e)
      this.closeButtons[i].addEventListener('mouseover', this.boundClose, false)
    }
  }


  destroy() {
    let i

    let openButtonsLength = this.openButtons.length
    for (i = 0; i < openButtonsLength; i++)
      this.openButtons[i].removeEventListener('mouseover', this.boundOpen, false)

    let closeButtonsLength = this.closeButtons.length
    for (i = 0; i < closeButtonsLength; i++)
      this.closeButtons[i].removeEventListener('mouseover', this.boundClose, false)
  }
}
