export default class ToggleSection {
  constructor(object) {
    this.toggleSection = object;
    this.setDefault();
  }

  setDefault() {
    if (this.toggleSection === undefined) this.toggleSection = {};

    if (this.buttonClass === undefined) this.buttonClass = '[data-js="toggle-section"]';

    if (this.sectionToToggle === undefined) this.sectionToToggle = '[data-js="section-to-toggle"]';

    if (this.sectionToToggleContent === undefined) this.sectionToToggleContent = '[data-js="section-content"]';
  }

  init() {
    this.buttons = document.querySelectorAll(`${this.buttonClass}`);
    this.buttons.forEach((button, index) => {
      this[`boundClick${index}`] = (e) => this.click(e);
      button.addEventListener('click', this[`boundClick${index}`]);
    });
  }

  click(e) {
    this.button = e.currentTarget;
    this.buttonParent = this.button.parentNode;
    this.section = this.buttonParent.querySelector(this.sectionToToggle);
    this.sectionContent = this.section.querySelector(this.sectionToToggleContent);
    this.sectionContentHeight = this.sectionContent.offsetHeight;

    this.section.classList.toggle('hidden-section');

    this.changeButtonText();

    if (this.section.offsetHeight === 0) {
      this.section.style.height = `${this.sectionContentHeight}px`;
    } else {
      this.section.style.height = '0px';
    }
  }

  changeButtonText() {
    if (this.button.classList.contains('--show')) {
      this.button.classList.remove('--show');
    } else {
      this.button.classList.add('--show');
    }
  }

  destroy() {
    this.buttons.forEach((button, index) => {
      button.removeEventListener('click', this[`boundClick${index}`]);
    });
  }
}
