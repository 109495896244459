import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';
import Accordions from '../classes/accordions';

export function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      },
    },
    options: {
      speed: 800,
    },
  });

  ovShare.init();

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    // alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    setTimeout(() => {
      alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    }, 300);
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

export function overlayMenu() {
  new Overlay({
    name: 'menu',
    events: {
      close: true,
      open: true,
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-menu"], a[href]',
        switch: '[data-js="trigger-search"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  }).init();

  let header;

  const onScroll = () => {
    header = document.querySelector('.header');
  };

  const onOpen = () => {
    if (header.classList.contains('--scroll')) {
      header.classList.add('--scroll-overlay-menu-opened');
    }
  };

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
  });

  mainLevel.init();

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
  });

  secondLevel.init();

  const onClose = () => {
    mainLevel.closeAll();
    secondLevel.closeAll();

    if (header.classList.contains('--scroll-overlay-menu-opened')) {
        header.classList.remove('--scroll-overlay-menu-opened');
    }

    const allClickedAndUnclickedElements = document.querySelectorAll('.clicked-element, .no-clicked-element');
    let i;
    let clickedOrUnclickedElement;

    for (i = 0; i < allClickedAndUnclickedElements.length; i += 1) {
      clickedOrUnclickedElement = allClickedAndUnclickedElements[i];
      clickedOrUnclickedElement.classList.remove('clicked-element');
      clickedOrUnclickedElement.classList.remove('no-clicked-element');
    }
  };

  const onCloseMainLevel = () => {
    secondLevel.closeAll();

    const clickedElement = document.querySelector('.overlay-menu__main-level-button.clicked-element');

    if (clickedElement) {
      const clickedElementParentElement = clickedElement.parentElement;
      const allClickedAndUnclickedElements = clickedElementParentElement.querySelectorAll(
        '.overlay-menu__second-level .clicked-element, .overlay-menu__second-level .no-clicked-element',
      );
      let i;
      let clickedOrUnclickedElement;

      for (i = 0; i < allClickedAndUnclickedElements.length; i += 1) {
        clickedOrUnclickedElement = allClickedAndUnclickedElements[i];
        clickedOrUnclickedElement.classList.remove('clicked-element');
        clickedOrUnclickedElement.classList.remove('no-clicked-element');
      }
    }
  };

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'scroll load',
    function: onScroll,
  });

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onOpenOverlayMenu',
    function: onOpen,
  });

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseOverlayMenu',
    function: onClose,
  });

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseAccordionsMainLevel onCloseAllAccordionsMainLevel',
    function: onCloseMainLevel,
  });

  OBSERVER.on('overlayMenu');

  function hoverAndClickEvent() {
    let allElements;
    let element;
    let hoverElement;
    let clickedElement;
    let elementData;
    let parentElement;
    let i;
    let j;

    // Hover in
    const onHoverIn = (e) => {
      hoverElement = e.target;
      elementData = hoverElement.getAttribute('data-js');

      if (elementData === 'hoverOrclickLink') {
        allElements = document.querySelectorAll('[data-js="hoverOrclickLink"]');
      } else {
        parentElement = document.querySelector('.clicked-element').parentElement;
        allElements = parentElement.querySelectorAll('[data-js="hoverOrclickSubLink"]');
      }

      for (i = 0; i < allElements.length; i += 1) {
        element = allElements[i];
        element.classList.remove('hover-element');
        element.classList.add('no-hover-element');
      }

      hoverElement.classList.remove('no-hover-element');
      hoverElement.classList.add('hover-element');
    };

    // Hover out
    const onHoverOut = (e) => {
      hoverElement = e.currentTarget;
      elementData = hoverElement.getAttribute('data-js');

      if (elementData === 'hoverOrclickLink') {
        allElements = document.querySelectorAll('[data-js="hoverOrclickLink"]');
      } else {
        parentElement = document.querySelector('.clicked-element').parentElement;
        allElements = parentElement.querySelectorAll('[data-js="hoverOrclickSubLink"]');
      }

      for (i = 0; i < allElements.length; i += 1) {
        element = allElements[i];
        element.classList.remove('hover-element');
        element.classList.remove('no-hover-element');
      }
    };

    // Click
    const onClick = (e) => {
      clickedElement = e.currentTarget;
      elementData = hoverElement.getAttribute('data-js');

      if (elementData === 'hoverOrclickLink') {
        allElements = document.querySelectorAll('[data-js="hoverOrclickLink"]');
      } else {
        parentElement = document.querySelector('.clicked-element').parentElement;
        allElements = parentElement.querySelectorAll('[data-js="hoverOrclickSubLink"]');
      }

      if (clickedElement.classList.contains('clicked-element')) {
        for (i = 0; i < allElements.length; i += 1) {
          element = allElements[i];
          element.classList.remove('no-clicked-element');
        }
        clickedElement.classList.remove('clicked-element');
      } else {
        for (j = 0; j < allElements.length; j += 1) {
          element = allElements[j];
          element.classList.remove('clicked-element');
          element.classList.add('no-clicked-element');
        }
        clickedElement.classList.remove('no-clicked-element');
        clickedElement.classList.add('clicked-element');
      }
    };

    OBSERVER.add({
      name: 'hoverAndClick',
      events: 'mouseenter',
      targets: '[data-js="hoverOrclickLink"], [data-js="hoverOrclickSubLink"]',
      function: onHoverIn,
    });
    OBSERVER.add({
      name: 'hoverAndClick',
      events: 'mouseleave',
      targets: '[data-js="hoverOrclickLink"], [data-js="hoverOrclickSubLink"]',
      function: onHoverOut,
    });
    OBSERVER.add({
      name: 'hoverAndClick',
      events: 'click',
      targets: '[data-js="hoverOrclickLink"], [data-js="hoverOrclickSubLink"]',
      function: onClick,
    });

    OBSERVER.on('hoverAndClick');
  }

  hoverAndClickEvent();
}

export function overlaySearch() {
  const search = new Overlay({
    name: 'search',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        trigger: '[data-js="trigger-search"]',
        close: '[data-js="close-search"]',
      },
    },
    animations: {
      selector: '.overlay-search__container',
      styles: [{
        property: 'height',
        value: '100%',
      }],
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  search.init();

  function onOpenComplete() {
    const inputSearch = document.querySelector('.js-overlay-search-input');
    inputSearch.focus();
  }

  function onCloseComplete() {
    const inputSearch = document.querySelector('.js-overlay-search-input');
    inputSearch.value = '';
  }

  // function changeSection(e) {
  //   const inputSearch = e;
  //   const html = document.querySelector('html');

  //   if (inputSearch.target.value.length >= 3 && html.dataset.section !== 'fast-results') {
  //     search.trigger('fast-results');
  //   } else if (inputSearch.target.value.length < 3 && html.dataset.section !== 'suggestions') {
  //     search.trigger('suggestions');
  //   }
  // }

  function onClearInput() {
    const html = document.querySelector('html');

    if (html.dataset.section !== 'suggestions') {
      search.trigger('suggestions');
    }
  }

  let ajaxRequest = false;
    const changeSection = (e) => {
      const inputSearch = e;
      const html = document.querySelector('html');

      if (ajaxRequest) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort();
        if (html.dataset.section !== 'suggestions') {
          search.trigger('suggestions');
        }
      }

      if (inputSearch.target.value.length >= 3) {
        ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: e.currentTarget.value },
          update: { 'rechercheAutocomplete::autocomplete': '.overlay-search__section-content #autocomplete' },
          complete(data) {
            // Results loaded.
            ajaxRequest = false;

            // On affiche l'overlay des suggestions juste si ya des résultats
            if (data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
              if (html.dataset.section !== 'fast-results') {
                search.trigger('fast-results');
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (html.dataset.section !== 'suggestions') {
                search.trigger('suggestions');
              }
            }
          },
        });
      } else if (inputSearch.target.value.length < 3 && html.dataset.section !== 'suggestions') {
        search.trigger('suggestions');
      }
    };

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onOpenCompleteOverlaySearch',
    function: onOpenComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onCloseCompleteOverlaySearch',
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'input',
    targets: '.js-overlay-search-input',
    function: changeSection,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    targets: '.js-overlay-search-clear-input',
    function: onClearInput,
  });
  OBSERVER.on('overlaySearch');
}

export function overlayCalendar() {
  new Overlay({
    name: 'calendar',
    click: {
      buttons: {
        toggle: '[data-js="toggle-calendar"]',
        close: '[data-js="close-calendar"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  }).init();
}

export function overlayPopup() {
    if (document.querySelector('[data-overlay="popup"]')) {
      const ovPopup = new Overlay({
        name: 'popup',
        events: {
          open: true,
          closeComplete: true,
        },
        click: {
          buttons: {
            close: '[data-js="close-overlay-popup"]',
          },
        },
        timeout: {
          delay: 2000,
        },
        options: {
          speed: 800,
        },
      });

      ovPopup.init();

      // Fonction permettant de donner un attribut height au container de l'overlay
      const addHeightAttributeToContainer = (nameEventOverlay, targetContainerOverlay) => {
        const containerOverlay = document.querySelector(targetContainerOverlay);

        function onOpen() {
          containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
        }

        function onCloseComplete() {
          containerOverlay.style.height = null;
        }

        function onResize() {
          containerOverlay.style.height = null;
          containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
        }

        OBSERVER.add({
          name: `overlay${nameEventOverlay}`,
          events: `onOpenOverlay${nameEventOverlay}`,
          function: onOpen,
        });
        OBSERVER.add({
          name: `overlay${nameEventOverlay}`,
          events: `onCloseCompleteOverlay${nameEventOverlay}`,
          function: onCloseComplete,
        });
        OBSERVER.add({
          name: `overlay${nameEventOverlay}`,
          events: 'resize',
          function: onResize,
        });
        OBSERVER.on(`overlay${nameEventOverlay}`);
      };

      // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
      addHeightAttributeToContainer('Popup', '[data-overlay-container="popup"]');
    }
}

export function overlayDirectory() {
  window.overlayDirectory = new Overlay({
    name: 'directory',
    click: {
      buttons: {
        open: '[data-js="open-directory"]',
        close: '[data-js="close-directory"]',
        switch: '.js-share',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  window.overlayDirectory.init();
}
