// Documentation: https://swiperjs.com/swiper-api
import barba from '@barba/core';
import Swiper from 'swiper/bundle';
import { OBSERVER } from '../plugins';
import { photoCredits } from './functions';

// Swiper de la bannière
export function swiperHomeBanner() {
  // Vérifie l'existence du swiper
  const homeBannerExist = document.querySelector('.layout__home-banner');

  if (homeBannerExist) {
    // Si il y a plus qu'une slide
    if (document.querySelectorAll('[data-swiper="home-banner-slide"]').length > 1) {
      // Création du slider
      window['home-banner'] = new Swiper('[data-swiper="home-banner"]', {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 7000,
        },
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '[data-swiper="home-banner-pagination"]',
          type: 'bullets',
          clickable: true,
        },
      });
      window['home-banner'].on('slideChange', () => {
        const bannerPhotoCredits = document.querySelector('.visible-credits');
        if (bannerPhotoCredits) {
            bannerPhotoCredits.classList.remove('visible-credits');
        }
      });

      barba.hooks.afterLeave(() => {
        window['home-banner'].destroy();
      });
    }
  }
}

// Swiper de l'alerte
export function swiperAlert() {
  // Vérifie l'existence du swiper
  const alertExist = document.querySelector('.layout__alert');

  if (alertExist) {
    const onClose = new CustomEvent('onCloseAlert');
    const container = document.querySelector('.layout__alert');
    const closeAlert = () => {
      dispatchEvent(onClose);
      container.classList.add('closed');
    };

    OBSERVER.add({
      name: 'alert',
      events: 'click',
      function: closeAlert,
      targets: '[data-swiper="swiper-alert-close"]',
    });
    OBSERVER.on('alert');

    // Si il y a plus qu'une slide
    if (document.querySelectorAll('[data-swiper="swiper-alert-slide"]').length > 1) {
      // Création du slider
      window['swiper-alert'] = new Swiper('[data-swiper="swiper-alert"]', {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 7000,
        },
        slidesPerView: 1,
        pagination: {
          el: '[data-swiper="swiper-alert-pagination"]',
          type: 'fraction',
        },
        navigation: {
          prevEl: '[data-swiper="swiper-alert-prev"]',
          nextEl: '[data-swiper="swiper-alert-next"]',
        },
        runCallbacksOnInit: true,
        on: {
          init(swm) {
            const fraction = document.querySelector('[data-swiper="swiper-alert-pagination-fraction"]');
            fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
          },
          slideChange(swm) {
            const fraction = document.querySelector('[data-swiper="swiper-alert-pagination-fraction"]');
            fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
          },
        },
      });

      barba.hooks.afterLeave(() => {
        window['swiper-alert'].destroy();
      });
    }
  }
}

// Swiper de l'alerte en mobile
export function swiperAlertMobile() {
  // Vérifie l'existence du swiper
  const alertMobileExist = document.querySelector('.layout__alert-mobile');

  if (alertMobileExist) {
    const onClose = new CustomEvent('onCloseAlert');
    const closeAlert = () => {
      dispatchEvent(onClose);
      document.documentElement.style.setProperty('--alert-mobile-height', '0px');
    };

    let screenWidth;

    const checkScreenWidth = () => {
      screenWidth = window.innerWidth;

      if (screenWidth <= 1100) {
        document.documentElement.style.setProperty('--alert-mobile-height', '40px');
      } else {
        document.documentElement.style.setProperty('--alert-mobile-height', '0px');
      }
    };

    checkScreenWidth();

    OBSERVER.add({
      name: 'checkScreenWidth',
      events: 'resize',
      function: checkScreenWidth,
    });
    OBSERVER.on('checkScreenWidth');

    OBSERVER.add({
      name: 'alert-mobile',
      events: 'click',
      function: closeAlert,
      targets: '[data-swiper="swiper-alert-mobile-close"]',
    });
    OBSERVER.on('alert-mobile');

    // Si il y a plus qu'une slide
    if (document.querySelectorAll('[data-swiper="swiper-alert-mobile-slide"]').length > 1) {
      // Création du slider
      window['alert-mobile'] = new Swiper('[data-swiper="swiper-alert-mobile"]', {
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        autoplay: {
          delay: 7000,
        },
        slidesPerView: 'auto',
        pagination: {
          el: '[data-swiper="swiper-alert-mobile-pagination"]',
          type: 'fraction',
        },
        navigation: {
          prevEl: '[data-swiper="swiper-alert-mobile-prev"]',
          nextEl: '[data-swiper="swiper-alert-mobile-next"]',
        },
        runCallbacksOnInit: true,
        on: {
          init(swm) {
            const fraction = document.querySelector('[data-swiper="swiper-alert-mobile-pagination-fraction"]');
            fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
          },
          slideChange(swm) {
            const fraction = document.querySelector('[data-swiper="swiper-alert-mobile-pagination-fraction"]');
            fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
          },
        },
      });

      barba.hooks.afterLeave(() => {
        window['alert-mobile'].destroy();
      });
    }
  } else {
    document.documentElement.style.setProperty('--alert-mobile-height', '0px');
  }
}

// Swiper des actualités de l'accueil en mobile
export function swiperHomeNewsMobile() {
  // Vérifie l'existence du swiper
  const homeNewsExist = document.querySelector('.layout__home-news');

  if (homeNewsExist) {
    // Si il y a plus qu'une slide
    if (document.querySelectorAll('[data-swiper="home-news-mobile-slide"]').length > 1) {
      window['home-news-mobile'] = new Swiper('[data-swiper="home-news-mobile"]', {
        loop: true,
        speed: 700,
        slidesPerView: 'auto',
        spaceBetween: 10,
        autoplay: {
          delay: 7000,
        },
        centeredSlides: true,
        breakpoints: {
          400: {
            spaceBetween: 20,
          },
          500: {
            spaceBetween: 30,
          },
          600: {
            spaceBetween: 40,
          },
          700: {
            spaceBetween: 50,
          },
        },
      });

      window.lazyload.update();

      barba.hooks.afterLeave(() => {
        window['home-news-mobile'].destroy();
      });
    }
  }
}

// Swiper des événements de l'accueil
export function swiperHomeEvents() {
  // Vérifie l'existence du swiper
  const homeEventsExist = document.querySelector('.layout__home-events');

  function animatedLetters() {
    // Déclaration des constantes
    const activeSlideExist = document.querySelector('.home-events .swiper-slide-active');

    let activeSlides;

    if (activeSlideExist) {
        activeSlides = document.querySelectorAll('.home-events .swiper-slide-active, .home-events .swiper-slide-duplicate-active');
    } else {
        activeSlides = document.querySelectorAll('.home-events .swiper-slide');
    }

    // Déclaration des variables de boucle
    let i;
    let j;

    for (i = 0; i < activeSlides.length; i += 1) {
      // Déclaration de la variable de délai qui va s'incrémenter
      let transitionDelayValue = 0;

      const activeSlide = activeSlides[i];
      const activeSlideTitle = activeSlide.querySelector('.event-big-card__title');
      const activeSlideTitleLetters = activeSlide.querySelectorAll('.span-letter');
      activeSlideTitle.style.opacity = '1';

      for (j = 0; j < activeSlideTitleLetters.length; j += 1) {
        const activeSlideTitleLetter = activeSlideTitleLetters[j];
        activeSlideTitleLetter.style.transitionDelay = `${transitionDelayValue}ms`;
        activeSlideTitleLetter.style.opacity = '1';
        activeSlideTitleLetter.style.transform = 'none';

        transitionDelayValue += 30;
      }
    }
  }

  function reverseAnimatedLetters() {
    // Déclaration des constantes
    const swiperSlide = '.home-events .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-duplicate):not(.swiper-slide-duplicate-active)';
    const swiperDuplicateSlide = '.home-events .swiper-slide-duplicate:not(.swiper-slide-duplicate-active):not(.swiper-slide-active)';
    const inactiveSlides = document.querySelectorAll(`${swiperSlide}, ${swiperDuplicateSlide}`);

    // Déclaration des variables de boucle
    let i;
    let j;

    for (i = 0; i < inactiveSlides.length; i += 1) {
      const inactiveSlide = inactiveSlides[i];
      const inactiveSlideTitleLetters = inactiveSlide.querySelectorAll('.span-letter');

      for (j = 0; j < inactiveSlideTitleLetters.length; j += 1) {
        const activeSlideTitleLetter = inactiveSlideTitleLetters[j];
        activeSlideTitleLetter.style.transitionDelay = '0ms';
        activeSlideTitleLetter.style.transform = 'translate3d(0, 100%, 0)';
        activeSlideTitleLetter.style.opacity = '0';
      }
    }
  }

  if (homeEventsExist) {
    // Si il y a plus qu'une slide
    if (document.querySelectorAll('[data-swiper="home-events-slide"]').length > 1) {
      // Création du slider
      window['home-events'] = new Swiper('[data-swiper="home-events"]', {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '[data-swiper="swiper-home-events-pagination"]',
          type: 'fraction',
        },
        navigation: {
          prevEl: '[data-swiper="swiper-home-events-prev"]',
          nextEl: '[data-swiper="swiper-home-events-next"]',
        },
        preventInteractionOnTransition: true,
        runCallbacksOnInit: true,
        on: {
          init(swm2) {
            const fraction = document.querySelector('[data-swiper="swiper-home-events-pagination-fraction"]');
            fraction.innerHTML = `${swm2.realIndex + 1}/${swm2.slides.length - (swm2.loopedSlides * 2)}`;
          },
          slideChange(swm2) {
            const fraction = document.querySelector('[data-swiper="swiper-home-events-pagination-fraction"]');
            fraction.innerHTML = `${swm2.realIndex + 1}/${swm2.slides.length - (swm2.loopedSlides * 2)}`;
          },
          slideChangeTransitionStart() {
            animatedLetters();
            reverseAnimatedLetters();
          },
        },
      });

      window.lazyload.update();

      barba.hooks.afterLeave(() => {
        window['home-events'].destroy();
      });
    } else {
      setTimeout(() => {
        animatedLetters();
      }, 100);
    }
  }
}

// Swiper en bas de l'accueil
export function swiperHomeBottom() {
  // Vérifie l'existence du swiper
  const homeBottomExist = document.querySelector('.layout__home-bottom-swiper');

  if (homeBottomExist) {
    // Si il y a plus qu'une slide
    if (document.querySelectorAll('[data-swiper="home-bottom-swiper-slide"]').length > 1) {
      window['home-bottom-swiper'] = new Swiper('[data-swiper="home-bottom-swiper"]', {
        speed: 700,
        slidesPerView: 'auto',
        spaceBetween: 10,
        centeredSlides: false,
        breakpoints: {
          400: {
            spaceBetween: 20,
          },
          500: {
            spaceBetween: 30,
          },
        },
        navigation: {
          prevEl: '[data-swiper="home-bottom-swiper-prev"]',
          nextEl: '[data-swiper="home-bottom-swiper-next"]',
        },
      });

      window.lazyload.update();

      barba.hooks.afterLeave(() => {
        window['home-bottom-swiper'].destroy();
      });
    }
  }
}

// Swiper des actualités
export function swiperNews() {
  // Vérifie l'existence du swiper
  const swiperNewsExist = document.querySelector('.layout__news-swiper');

  if (swiperNewsExist) {
    window['news-swiper'] = new Swiper('[data-swiper="news-swiper"]', {
        loop: false,
        speed: 700,
        // spaceBetween: 10,
        centeredSlides: false,
        slidesPerView: 'auto',
        // breakpoints: {
        //   400: {
        //     spaceBetween: 20,
        //   },
        //   500: {
        //     spaceBetween: 30,
        //   },
        //   700: {
        //     spaceBetween: 45,
        //   },
        // },
        navigation: {
          prevEl: '[data-swiper="news-swiper-prev"]',
          nextEl: '[data-swiper="news-swiper-next"]',
        },
        on: {
            init(swiper) {
              if (swiper.isBeginning && swiper.isEnd) {
                document.querySelector(swiper.params.navigation.prevEl).style.display = 'none';
                document.querySelector(swiper.params.navigation.nextEl).style.display = 'none';
              }
            },
            resize(swiper) {
              if (document.querySelector(swiper.params.el)) {
                if (swiper.isBeginning && swiper.isEnd) {
                  document.querySelector(swiper.params.navigation.prevEl).style.display = 'none';
                  document.querySelector(swiper.params.navigation.nextEl).style.display = 'none';
                } else {
                  document.querySelector(swiper.params.navigation.prevEl).style.display = 'flex';
                  document.querySelector(swiper.params.navigation.nextEl).style.display = 'flex';
                }
              }
            },
          },
    });

    window.lazyload.update();

    barba.hooks.afterLeave(() => {
      window['news-swiper'].destroy();
    })
  }
}

// Swiper des événements de la page d'événement détail
export function swiperEvents() {
  // Vérifie l'existence du swiper
  const eventsExist = document.querySelector('.layout__events');

  function animatedLetters() {
    const activeSlideExist = document.querySelector('.events .swiper-slide-active');

    let activeSlides;

    if (activeSlideExist) {
        activeSlides = document.querySelectorAll('.events .swiper-slide-active, .events .swiper-slide-duplicate-active');
    } else {
        activeSlides = document.querySelectorAll('.events .swiper-slide');
    }

    // Déclaration des variables de boucle
    let i;
    let j;

    for (i = 0; i < activeSlides.length; i += 1) {
      // Déclaration de la variable de délai qui va s'incrémenter
      let transitionDelayValue = 0;

      const activeSlide = activeSlides[i];
      const activeSlideTitle = activeSlide.querySelector('.event-big-card__title');
      const activeSlideTitleLetters = activeSlide.querySelectorAll('.span-letter');
      activeSlideTitle.style.opacity = '1';

      for (j = 0; j < activeSlideTitleLetters.length; j += 1) {
        const activeSlideTitleLetter = activeSlideTitleLetters[j];
        activeSlideTitleLetter.style.transform = 'none';
        activeSlideTitleLetter.style.transitionDelay = `${transitionDelayValue}ms`;
        activeSlideTitleLetter.style.opacity = '1';

        transitionDelayValue += 30;
      }
    }
  }

  function reverseAnimatedLetters() {
    // Déclaration des constantes
    const swiperSlide = '.events .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-duplicate):not(.swiper-slide-duplicate-active)';
    const swiperDuplicateSlide = '.events .swiper-slide-duplicate:not(.swiper-slide-duplicate-active):not(.swiper-slide-active)';
    const inactiveSlides = document.querySelectorAll(`${swiperSlide}, ${swiperDuplicateSlide}`);

    // Déclaration des variables de boucle
    let i;
    let j;

    for (i = 0; i < inactiveSlides.length; i += 1) {
      const inactiveSlide = inactiveSlides[i];
      const inactiveSlideTitleLetters = inactiveSlide.querySelectorAll('.span-letter');

      for (j = 0; j < inactiveSlideTitleLetters.length; j += 1) {
        const activeSlideTitleLetter = inactiveSlideTitleLetters[j];
        activeSlideTitleLetter.style.transitionDelay = '0ms';
        activeSlideTitleLetter.style.transform = 'translate3d(0, 100%, 0)';
        activeSlideTitleLetter.style.opacity = '0';
      }
    }
  }

  if (eventsExist) {
    // Si il y a plus qu'une slide
    if (document.querySelectorAll('[data-swiper="events-slide"]').length > 1) {
      // Création du slider
      window['events-swiper'] = new Swiper('[data-swiper="events"]', {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '[data-swiper="swiper-events-pagination"]',
          type: 'fraction',
        },
        navigation: {
          prevEl: '[data-swiper="swiper-events-prev"]',
          nextEl: '[data-swiper="swiper-events-next"]',
        },
        preventInteractionOnTransition: true,
        runCallbacksOnInit: true,
        on: {
          init(swm4) {
            const fraction = document.querySelector('[data-swiper="swiper-events-pagination-fraction"]');
            fraction.innerHTML = `${swm4.realIndex + 1}/${swm4.slides.length - (swm4.loopedSlides * 2)}`;
          },
          slideChange(swm4) {
            const fraction = document.querySelector('[data-swiper="swiper-events-pagination-fraction"]');
            fraction.innerHTML = `${swm4.realIndex + 1}/${swm4.slides.length - (swm4.loopedSlides * 2)}`;
          },
          slideChangeTransitionStart() {
            animatedLetters();
            reverseAnimatedLetters();
          },
        },
      });

      window.lazyload.update();

      barba.hooks.afterLeave(() => {
        window['events-swiper'].destroy();
      });
    } else {
        setTimeout(() => {
            animatedLetters();
        }, 100);
    }
  }
}
