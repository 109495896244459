import Accordions from '../classes/accordions';
import { OBSERVER } from '../plugins';

export default function scrollToBlockAndOpenRubric(object) {
  const itemSelector = object.selector;
  const itemRubric = object.rubric;

  const onClick = () => {
    const rubric = new Accordions();
    rubric.open(document.querySelector(itemRubric));
  };

  OBSERVER.add({
    name: 'scrollToBlockAndOpenRubric',
    events: 'click',
    targets: itemSelector,
    function: onClick,
  });

  OBSERVER.on('scrollToBlockAndOpenRubric');
}
