/* eslint-disable no-restricted-globals */
import $ from 'jquery';
import Accordions from '../classes/accordions';

export default function initRepertoire() {
  const accordions = new Accordions();
  accordions.offset = 0;
  accordions.init();

  let urlSegments = window.location.pathname.split('/');
  let cat;

  //
  // Arrivé sur la page avec des segments dans l'url
  //

  if (urlSegments.length > 2 && urlSegments[2] !== '') { // S'il y a un segment de catégorie dans l'url
    if (document.querySelector(`#accordion-${urlSegments[2]}`)) { // S'il y a bien une rubrique de sous-catégorie qui correspond au segment d'url
      const rubric = document.querySelector(`#accordion-${urlSegments[2]}`);
      accordions.open(rubric);
    }
  }
  if (urlSegments.length > 3 && urlSegments[3] !== '') { // S'il y a un segment d'entreprise dans l'url
    // Call en ajax l'update du contenu de l'overlay
    $.request('onLoadOverlay', {
      data: { slug: urlSegments[3] },
    });
    window.overlayDirectory.open(); // Ouvrir l'overlay
  }

  //
  // Modification de l'url selon des actions
  //

  // Modification de l'url à l'ouverture d'une rubrique (sous-catégorie)
  $('.js-accordion .js-accordion-toggle').click(function () {
    cat = $(this).parent();
    if (cat.hasClass('js-accordion-opened')) {
      history.pushState({}, '', `/repertoire/${cat.data('slug')}`);
    } else {
      history.pushState({}, '', '/repertoire');
    }
  });

  // Ajout du slug dans l'url sur le click d'une entreprise
  $('.js-accordions button').click(function () {
    const slug = $(this).data('slug');
    history.pushState({}, '', slug);
  });

  // Retrait du slug de l'entreprise de l'url à la fermeture de l'overlay
  $('[data-js=close-directory]').click(() => {
    urlSegments = window.location.pathname.split('/');
    history.pushState({}, '', `/repertoire/${urlSegments[2]}`);
  });

  // Retrait du slug de l'entreprise de l'url à l'ouverture de l'overlay de partage
  $('.share-directory').click(() => {
    urlSegments = window.location.pathname.split('/');
    history.pushState({}, '', `/repertoire/${urlSegments[2]}`);
  });
}
