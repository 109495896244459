import $ from 'jquery';
import { getElementOffset, isMobile, isVisible } from './helper';
import { OBSERVER } from '../plugins';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.calendar').dataset.list;
  const currentDate = document.querySelector('.calendar').dataset.date;

  $.fn.datepicker.dates.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre',
      'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0,
  };

  const datesEnabled = eventsDatesList.split(',');

  $('.calendar')
    .datepicker({
      language: 'fr',
      maxViewMode: 0,
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      beforeShowDay: (date) => {
        // Rendre seulement les dates de la liste d'événements disponibles
        let returnBoolean;
        const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        if (datesEnabled.indexOf(allDates) !== -1) {
          returnBoolean = true;
        } else {
          returnBoolean = false;
        }
        return returnBoolean;
      },
    })
    .on('changeDate', (e) => {
      // Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
      const theTimestamp = Date.parse(e.date) / 1000;
      $.request('onChangeDate', {
        data: { dateFilter: theTimestamp },
        // update: {'evenementlist::eventslist':'#eventsWrapper'},
        // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
        complete: (data) => {
          data.then((data2) => {
            // eslint-disable-next-line no-undef
            Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
          });
        },
      });
      // Fermer l'overlay quand on clique
      $('#overlayCalendar .wrapper a.close span.x').trigger('click');
    });

  $('.calendar').datepicker('update', currentDate);
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function changeSelect(select) {
  $(select).next('.tail-select').find('.dropdown-option').on('click', () => {
    $(select).trigger('change');
  });
}


export function changeMultipleSelects(selector) {
  const selects = $(selector);
  selects.each(index => {
    $(selects[index]).next('.tail-select').find('.dropdown-option').on('click', () => {
      $(selects[index]).trigger('change');
    });
  });

}

export function tableVisibility(btn, table, displayText, hideText) {
  let self
  let myTable = $(table)
  // let displayText = 'Afficher les détails mensuels'
  // let hideText = 'Masquer les détails mensuels'

  myTable.animate({ height: 'hide' }, 10)
  $(btn).on('click', function () {
    self = $(this)

    if (myTable.hasClass('open')) {
      self.text(displayText)
      myTable.stop(true, false).animate({ height: 'hide' }, 400)
      myTable.removeClass('open')
    } else {
      self.text(hideText)
      myTable.stop(true, false).animate({ height: 'show' }, 400)
      myTable.addClass('open')
    }
  })
}

export function photoCredits() {
  function touch(e) {
    const isCreditsVisible = e.currentTarget.nextElementSibling.classList.contains('visible-credits');
    if (isCreditsVisible) {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    } else {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    }
  }

  if (!isMobile()) {
    const hoverIn = (e) => {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    };
    const hoverOut = (e) => {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    };

    OBSERVER.add({
      name: 'hover',
      events: 'mouseenter',
      targets: '.photo-credits__hover-item',
      function: hoverIn,
    });
    OBSERVER.add({
      name: 'hover',
      events: 'mouseleave',
      targets: '.photo-credits__hover-item',
      function: hoverOut,
    });
    OBSERVER.on('hover');
  } else if (isMobile()) {
    OBSERVER.add({
      name: 'touch',
      events: 'click',
      targets: '.photo-credits__hover-item',
      function: touch,
    });
    OBSERVER.on('touch');
  }
}

export function homeNews() {
  // Si le bloc d'actus sur l'accueil existe
  if (document.querySelector('[data-js="home-news"]') || document.querySelector('[data-js="comp-news"]')) {
    const currentElement = document.querySelector('[data-current-home-news-image]');
    const totalElement = document.querySelector('[data-total-home-news-image]');

    const elements = document.querySelectorAll('[data-home-news-image]');
    const elementsLength = elements.length;

    totalElement.textContent = elementsLength;

    let progressVal = 0;
    const strokeVal = 1.88;
    const x = document.querySelector('.fraction-circle__progress-circle-prog');

    x.style.strokeDasharray = `${progressVal * (strokeVal)} 999`;

    let i;
    let currentNumber = 0;
    const elementsOffsetArray = [];

    const onResize = () => {
      for (i = 0; i < elementsLength; i += 1) {
        const element = elements[i];
        const elementOffset = getElementOffset({ element }).top;
        elementsOffsetArray.push(elementOffset - 200);
      }
    };

    onResize();

    const onScroll = () => {
      for (i = 0; i < elementsLength; i += 1) {
        const scrollOffset = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
        const elementOffset = elementsOffsetArray[i];

        if (typeof elementOffset === 'number') {
          if (scrollOffset > elementOffset) {
            currentNumber = i + 1;
          } else if (scrollOffset < elementsOffsetArray[0]) {
            currentNumber = 0;
          }
        }
      }
      currentElement.textContent = currentNumber;

      progressVal = (currentNumber / elementsLength) * 100;
      x.style.strokeDasharray = `${progressVal * (strokeVal)} 999`;
    };

    onScroll();

    OBSERVER.add({
      name: 'homeNews',
      events: 'scroll',
      function: onScroll,
    });

    OBSERVER.add({
      name: 'homeNews',
      events: 'resize',
      function: onResize,
    });

    OBSERVER.on('homeNews');
  }
}

// Fonction permettant d'animer les lettres d'un titre séparemment
export function animatedTitle() {
  // Déclaration des constantes
  const titlesToAnimate = document.querySelectorAll('[data-js="title-to-animate"]');

  // Déclaration des variables de boucles
  let i;
  let j;
  let k;

  // Boucle servant à séparer le titre en array de mots
  for (i = 0; i < titlesToAnimate.length; i += 1) {
    // Déclaration de la variable de délai qui va s'incrémenter
    let transitionDelayValue = 0;

    const title = titlesToAnimate[i];
    let titleString = title.innerHTML;

    // Remplace les balises <br> par des balises <br> avec des espaces avant et après pour éviter des problèmes
    if (titleString.includes('<br>')) {
      titleString = titleString.replace('<br>', ' <br> ');
    }
    if (titleString.includes('<br/>')) {
      titleString = titleString.replace('<br/>', ' <br/> ');
    }
    if (titleString.includes('<br />')) {
      titleString = titleString.replace('<br />', ' <br /> ');
    }

    const words = titleString.split(' ');

    titleString = '';

    // Boucle servant à mettre chacun des mots dans des balises <span>
    for (j = 0; j < words.length; j += 1) {
      const word = words[j];
      let wordBr;

      if (word === '<br>' || word === '<br/>' || word === '<br />') {
        wordBr = true;
      }

      // Vérifie si le mot est une balise <br>
      if (wordBr !== true) {
        const letters = word.split('');

        // Création des balises <span> pour les mots
        const spanContainer = document.createElement('span');
        spanContainer.classList.add('span-container');

        const spanWord = document.createElement('span');
        spanWord.classList.add('span-word');

        const spanSpace = document.createElement('span');
        spanSpace.classList.add('span-space');
        spanSpace.appendChild(document.createTextNode('\xa0'));

        // Boucle servant à mettre chacune des lettres dans des balises <span>
        for (k = 0; k < letters.length; k += 1) {
          const letter = letters[k];

          const spanLetter = document.createElement('span');
          spanLetter.classList.add('span-letter', 'js-scrollfire');

          spanLetter.appendChild(document.createTextNode(letter));
          spanLetter.style.transitionDelay = `${transitionDelayValue}ms`;
          spanWord.appendChild(spanLetter);

          transitionDelayValue += 30;
        }

        spanContainer.appendChild(spanWord);
        spanContainer.appendChild(spanSpace);

        titleString += spanContainer.outerHTML;
      } else {
        // Si le mot est une balise <br>, une balise <br> est créée et insérée dans le titre
        const br = document.createElement('br');
        titleString += br.outerHTML;
      }
    }

    title.innerHTML = titleString;
  }
}

export function updateLazyLoad() {
  const onResize = () => {
    window.lazyload.update();
  };

  OBSERVER.add({
    name: 'updateLazyLoad',
    events: 'resize',
    function: onResize,
  });

  OBSERVER.on('updateLazyLoad');
}

// Fonction permettant de show/hide le bouton retour vers le haut
export function buttonReturnToTop() {
  const className = 'active';
  const button = document.querySelector('.js-button-return-to-top');
  const html = document.querySelector('html');

  const onScroll = () => {
    if (window.scrollY > 20 && !button.classList.contains(className)) {
      button.classList.add(className);
    } else if (window.scrollY <= 20 && button.classList.contains(className) && !html.classList.contains('show-overlay')) {
      button.classList.remove(className);
    }
  };

  OBSERVER.add({
    name: 'buttonReturnToTop',
    events: 'scroll load',
    function: onScroll,
  });
  OBSERVER.on('buttonReturnToTop');
}

// Fonction permettant de faire disparaître le texte du sidemenu lorsque l'on atteint le form dans les pages d'emplois.
export function ifSectionVisible(object) {
  const element = document.querySelector(object.visibleElement);
  const elementToHide = document.querySelector(object.elementToHide);

  const checkIfElementIsVisible = () => {
    if (isVisible(element)) {
      elementToHide.classList.add('hidden');
    } else {
      elementToHide.classList.remove('hidden');
    }
  };

  OBSERVER.add({
    name: 'checkIfElementIsVisible',
    events: 'scroll',
    function: checkIfElementIsVisible,
  });
  OBSERVER.on('checkIfElementIsVisible');
}

export function setContentMinHeight() {
  const aside = document.querySelector('.aside__sticky-item');

  if (aside) {
    const onResize = () => {
      const asideHeight = aside.offsetHeight;

      document.documentElement.style.setProperty('--aside-height', `${asideHeight}px`);
    };

    onResize();

    OBSERVER.add({
      name: 'setMinHeight',
      events: 'resize',
      function: onResize,
    });

    OBSERVER.on('setMinHeight');
  }
}

// Fonction permettant d'ajouter une classe sur le header lorsque l'on scroll
export function headerScroll() {
  const header = document.querySelector('.header');

  const onScroll = () => {
    if (window.scrollY > 0) {
      header.classList.add('--scroll');
    } else {
      header.classList.remove('--scroll');
    }
  };

  OBSERVER.add({
    name: 'headerScroll',
    events: 'scroll load',
    function: onScroll,
  });

  OBSERVER.on('headerScroll');
}

export function faqButtons() {
  $('.js-letter-filter').click(function () {
    const currentLetter = $(this).data('faqletter');
    const letters = document.querySelectorAll('[data-faqletter]'); // Liste des boutons de lettres
    const sections = document.querySelectorAll('[data-faqsection]'); // Liste des sections de lettres

    letters.forEach((letter) => {
      letter.classList.remove('letters-filter__active');
      if (currentLetter !== 'all') {
        if (letter.dataset.faqletter === currentLetter) {
          letter.classList.add('letters-filter__active');
        }
      }
    });

    sections.forEach((section) => {
      if (currentLetter !== 'all') {
        // eslint-disable-next-line no-param-reassign
        section.style.display = 'none';
        if (section.dataset.faqsection === currentLetter) {
          $(section).fadeIn(500, 'swing');
        }
      } else {
        $(section).fadeIn(500, 'swing');
      }
    });
  });
}

export function initFancyBoxes() {
  $('[data-fancybox]').fancybox({
    buttons: [
      'zoom',
      'slideShow',
      'thumbs',
      'download',
      'close',
    ],
  });
}
