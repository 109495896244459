import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  backendKeyBind, changeSelect, changeMultipleSelects, ogContent, photoCredits, homeNews,
  animatedTitle, updateLazyLoad, buttonReturnToTop, calendar, ifSectionVisible, setContentMinHeight,
  headerScroll,
  faqButtons, initFancyBoxes,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, tailSelect, clearInput,
} from './functions/form';
import {
  overlayShare, overlayMenu, overlaySearch, overlayCalendar, overlayPopup, overlayDirectory,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formContact, formExample, formJobs, formNewsletter, formSuccess,
} from './functions/validation';
import masksPackage from './functions/masks';
import { removeHoverOnMobile, sizeTitle, hundredVH } from './functions/helper';
import { TyperSetup } from './vendor/typer';
import {
  swiperHomeBanner, swiperAlert, swiperAlertMobile, swiperHomeNewsMobile, swiperHomeEvents, swiperHomeBottom, swiperNews, swiperEvents,
} from './functions/sliders';
import { clickToScrollToBlock } from './functions/scrollToBlock';
import scrollToBlockAndOpenRubric from './functions/scrollToBlockAndOpenRubric';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';
import ToggleSection from './classes/toggleSection';
import initRepertoire from './functions/repertoire';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    window.lazyload = new LazyLoad();
    updateLazyLoad();
    overlayShare();
    backendKeyBind();
    removeHoverOnMobile();
    swiperAlert();
    swiperAlertMobile();
    clickToScrollToBlock({ selector: '[data-js="scroll-to-header"]', scrollTo: 'body' });
    overlayMenu();
    overlaySearch();
    overlayPopup();
    hundredVH();
    clearInput();
    // photoCredits();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
    window.lazyload.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formExample();
          animatedTitle();
          swiperEvents();
          swiperNews();
          homeNews();
          initFancyBoxes();
          swiperHomeNewsMobile();
          sizeTitle({
            selectors: '.page-title__title, .page-title-with-image__title',
            caract01: 40,
            caract02: 60,
            caract03: 80,
          });
          buttonReturnToTop();
          photoCredits();
          setContentMinHeight();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          headerScroll();
          changeMultipleSelects('.form-calculator-select');
          faqButtons();
          initFormBuilder(); // eslint-disable-line no-undef
          window.ToggleSection = new ToggleSection();
          window.ToggleSection.init();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: '404View',
        beforeEnter() {
          dynamicPackage();
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: '500View',
        beforeEnter() {
          dynamicPackage();
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          TyperSetup();
          swiperHomeBanner();
          sizeTitle({
            selectors: '.home-banner__title',
            caract01: 40,
            caract02: 60,
            caract03: 80,
          });
          clickToScrollToBlock({ selector: '[data-js="scroll-to-home-news"]', scrollTo: '[data-js="home-news"]' });
          homeNews();
          animatedTitle();
          swiperHomeEvents();
          swiperHomeBottom();
          swiperHomeNewsMobile();
          buttonReturnToTop();
          photoCredits();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          formsPackage();
          masksPackage();
          formContact();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-infos"]', scrollTo: '[data-js="infos"]' });
          scrollToBlockAndOpenRubric({ selector: '[data-js="scroll-to-hours"]', rubric: '#accordion-horaire' });
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          tailSelect();
          changeSelect('#catFilterSelect');
          calendar();
          overlayCalendar();
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'eventDetailView',
        beforeEnter() {
          dynamicPackage();
          animatedTitle();
          swiperEvents();
          initFancyBoxes();
          buttonReturnToTop();
          photoCredits();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          headerScroll();
          sizeTitle({
            selectors: '.page-title-with-image__title',
            caract01: 40,
            caract02: 60,
            caract03: 80,
          });
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect();
          changeSelect('#catFilterSelect');
          swiperNews();
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage();
          buttonReturnToTop();
          photoCredits();
          initFancyBoxes();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          headerScroll();
          sizeTitle({
            selectors: '.page-title-with-image__title',
            caract01: 40,
            caract02: 60,
            caract03: 80,
          });
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          changeSelect('#catFilterSelect');
          formJobs();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-jobs"]', scrollTo: '[data-js="jobs"]' });
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          buttonReturnToTop();
          ifSectionVisible({ visibleElement: '[data-js="form"]', elementToHide: '.aside__links' });
          photoCredits();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'jobDetailView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          formJobs();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          buttonReturnToTop();
          photoCredits();
          headerScroll();
          sizeTitle({
            selectors: '.page-title-with-image__title',
            caract01: 40,
            caract02: 60,
            caract03: 80,
          });
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          clearInput();
          tailSelect();
          changeSelect('#catFilterSelect');
          changeSelect('#catFilterSelectMobile');
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'directoryView',
        beforeEnter() {
          dynamicPackage();
          buttonReturnToTop();
          headerScroll();
          overlayDirectory();
          initRepertoire();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'promoView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          sizeTitle({
            selectors: '.promo-banner__title',
            caract01: 50,
            caract02: 60,
            caract03: 80,
          });
          animatedTitle();
          swiperEvents();
          buttonReturnToTop();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formNewsletter();
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'sectionsView',
        beforeEnter() {
          headerScroll();
          SCROLLFIRE.init();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          SCROLLFIRE.init();
        },
        afterLeave() {
          destroyMap();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
